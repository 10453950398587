 import Member from '../comps/Member.jsx';
 import Row from 'react-bootstrap/Row';

 function Team() {
    /*return(
        <div>
        <h2>Unser Team 2022/2023</h2>
        <p>Jedes Projekt ist nur so gut, wie die Personen, die das Projekt unterstützen. Hier seht ihr diejenigen, die für Mkx. DV verantwortlich sind.</p>
        <h3>Vorstand</h3>        
        <Row xs={1} md={2} lg={3}>
        <Member name="Tim Güldenpfennig" function="Vorsitzender" src="" />
        <Member name="Marvin Bellmann" function="stellv. Vorsitzender" src=""/>
        </Row><br/>
        <h3>Modulleiter</h3>
        <Row xs={1} md={2} lg={3}>
        <Member name="Tim Hübner" function="Head of MB" src="img/members/huebner.jpg" />
        <Member name="Sami Fatal Al-Yabroudi" function="Head of ET" src="img/members/sami.jpeg"/>
        </Row><br/>
        <h3>Sonstige Mitglieder</h3>
        <Row xs={1} md={2} lg={3}>
        <Member name="Remo-Peter Stiegler" function="ET-try-hard" src="img/members/remo.jpg" />
        <Member name="Dr. habil. Martin Dymek" function="Softwareexperte" src="img/members/martin.jpg"/>
        </Row>
        </div>
    )*/
return(
    <div>
        <h2>Team</h2>
        <p>Wir sind das T.U.C. Racing Team  und vertreten die Technische Universität Chemnitz bei dem internationalen Konstruktionswettbewerb „Formula Student“. Wir sind ein bunt gemischtes Team bestehend aus Technikbegeisterten, Organisationstalenten und leidenschaftlichen Rennfahrern. Gemeinsam arbeiten wir an einem klaren Ziel: die Entwicklung unseres eigenen konkurrenzfähigen Rennwagens mit E-Motor. Dabei stehen für uns Innovationskraft, Nachhaltigkeit und ein starker Teamgeist im Fokus.
        </p><p>
        Wir laden dich herzlich ein, das T.U.C. Racing Team näher kennenzulernen und gemeinsam mit uns die Formula Student  zu erleben.
        </p> 
        <hr />
        <h3>Vorstand</h3>
        <br />
        <Row xs={1} md={2} lg={3}>
            <Member name="Nicolai Winkler" function="Teamcaptain" src="img/members/nicolai_p.jpg"></Member>
            <Member name="Tim Hübner" function="Technical Projectlead" src="img/members/huebner_p.jpg"></Member>
            <Member name="Laura Menzel" function="Organisational Projectlead" src="img/members/laura_p.jpg"></Member>
        </Row>
        <hr />
        <h3>Modulleiter</h3>
        <br />
        <Row xs={1} md={2} lg={3}>
            <Member name="Martin Dymek" function="Head of Powertrain" src="img/members/martin_p.jpg" />
            <Member name="Giovanni Born" function="Head of Drivetrain" src="img/members/giovanni_p.jpg" />
            <Member name="Anthony Schmied" function="Head of Software" src="img/members/anthony_p.jpg" />
            <Member name="Florian Karl" function="Head of Electronics" src="img/members/florian_p.jpg"></Member>
            <Member name="Tim Güldenpfennig" function="Head of Accumulator" src="img/members/harry_p.jpg"></Member>
            <Member name="Marvin Bellmann" function="Head of Frame & Body" src="img/members/marvin_p.jpg"></Member>
            <Member name="Alexander Göthel" function="Head of Suspension" src="img/members/alex_p.jpg"></Member>
            <Member name="Paul Friedrich" function="Head of Ergonomics" src="img/members/paul_p.jpg"></Member>
            <Member name="Moritz Senn" function="Head of Driverless" src="img/members/moritz_p.jpg"></Member>
            <Member name="Jessica Biethahn" function="Head of Economics" src="img/members/jessi_p.jpg"></Member>
            <Member name="Mellyn Müller" function="Head of Media" src="img/members/mellyn_p.jpg"></Member>
            <Member name="Pascal Kirchner" function="Head of Cost Report" src="img/members/platzhalter.jpg"></Member>
        </Row>
    </div>
);
 }

 export default Team;
