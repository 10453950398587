import Thumbnail from '../comps/Thumbnail.jsx'
import Row from 'react-bootstrap/Row'

function Galerie() {
    return(
        <div>
            <h2>Die Bilder unserer Saisons</h2>
            <hr></hr>
            <br></br>

            <Row xs={1} md={2} lg={3} className='g-3'>
            <a href='/Saison_22_23'><Thumbnail name="Saison 2022/23" function="Mkx DV" src="img/cars/mkxdv2.png"></Thumbnail></a>
            <a href='/Saison_21_22'><Thumbnail name="Saison 2021/22" function="Mkx IV" src="img/cars/mkxiv.png"></Thumbnail></a>
            <a href='/Saison_20_21'><Thumbnail name="Saison 2020/21 (19/20)" function="Mkx III" src="img/cars/mkxiii2.png"></Thumbnail></a>
            <a href='/Saison_18_19'><Thumbnail name="Saison 2018/19" function="Mkx II" src="img/cars/mkxii2.png"></Thumbnail></a>
            <a href='/Saison_17_18'><Thumbnail name="Saison 2017/18" function="Mkx I" src="img/cars/mkxi2.png"></Thumbnail></a>
            
            </Row>
            


        </div>
        )
} 

export default Galerie;
